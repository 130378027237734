import "bootstrap/dist/js/bootstrap";
import $ from "jquery";

const getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;
  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
};

const getDomainByEnv = (env) => {
  switch (env) {
    case "dev":
      return "https://gw.wallet.charge-dev.net";
      break;
    case "stage":
      return "https://gw.wallet.charge-stage.net";
      break;
    case "production":
      return "https://gw.wallet.charge.io";
      break;
    default:
      return null;
      break;
  }
};

const getChromeStoreIdByEnv = (env) => {
  switch (env) {
    case "dev":
      return "bompadneakdjpcckfojdmgnhfbandeel";
      break;
    case "stage":
      return "oaoaioaolfgjalmajahhgaoelhgddjgo";
      break;
    case "production":
      return "eleopghbnmhnflnbjcjnlohdobcpoifd";
      break;
    default:
      return null;
      break;
  }
};

const errorHandler = () => {
  $("body").addClass("error-message");
  $("#title").text("Oops!");
  $("#sub-title").text("Something went wrong, try again later.");
  $("body").removeClass("loading");
};

const handleMissingParams = () =>
  window.location.replace("https://www.amifi.io/");

const handlePendingCard = () => {
  $("#title").text("Your money is waiting on your phone!");
  $("#sub-title").text(
    "To access your card open the AmiFi app on your mobile device."
  );
  $("body").removeClass("loading");
};

const handleIssuedCard = () => {
  $("#title").text("Your money is ready to spend!");
  $("#sub-title").text(
    "To access your card open the AmiFi app on your mobile device."
  );
  $("body").removeClass("loading");
};

const handleResetPassword = (domain) => {
  const email = getUrlParameter("email");

  const success = () => {
    $("#title").text("Email sent!");
    $("#sub-title").text(
      "Password reset email has been sent on your inbox, please use it to reset your password from the AmiFi."
    );
    $("body").removeClass("loading");
  };

  $.ajax({
    type: "POST",
    url: `${domain}/v1.0/users/verificationCode/new?type=password`,
    data: JSON.stringify({
      email,
    }),
    contentType: "application/json; charset=utf-8",
    success: success,
    error: errorHandler,
  });
};

const handleDeviceVerification = (domain) => {
  const token = getUrlParameter("token");
  const email = getUrlParameter("email");

  $("body").addClass("loading");

  const success = () => {
    $("#title").text("Congratulations!");
    $("#sub-title").text(
      "You have verified your device and now you can use the app."
    );
    $("body").removeClass("loading");
  };

  $.ajax({
    type: "POST",
    url: `${domain}/v1.0/users/verifyDevice`,
    data: JSON.stringify({
      email,
      token,
    }),
    contentType: "application/json; charset=utf-8",
    success: success,
    error: errorHandler,
    dataType: "json",
  });
};

const handleEmailVerification = (domain) => {
  const code = getUrlParameter("code");
  const email = getUrlParameter("email");

  const success = () => {
    $("#title").text("Congratulations!");
    $("#sub-title").text(
      "You have verified your email and now you can use the app."
    );
    $("body").removeClass("loading");
  };

  $.ajax({
    type: "POST",
    url: `${domain}/v1.0/users/verifyEmail`,
    data: JSON.stringify({
      email,
      verificationCode: code,
    }),
    contentType: "application/json; charset=utf-8",
    success: success,
    error: errorHandler,
  });
};

const handlePasswordVerification = () => {
  $("#title").text("Sorry, you must change your password using the mobile app");
  $("#sub-title").text("Please open the email on your mobile device");
  $("body").removeClass("loading");
};

const PENDING_VCC_TYPE = "pending_card";
const ISSUED_VCC_TYPE = "virtual_card";
const DEVICE_VERIFICATION_TYPE = "device_verification";
const EMAIL_VERIFICATION_TYPE = "email_verification";
const PASSWORD_VERIFICATION_TYPE = "password_verification";
const RESET_PASSWORD_TYPE = "reset_password";

function processByType(type, domain) {
  switch (type) {
    case PENDING_VCC_TYPE:
      handlePendingCard();
      break;
    case ISSUED_VCC_TYPE:
      handleIssuedCard();
      break;
    case DEVICE_VERIFICATION_TYPE:
      handleDeviceVerification(domain);
      break;
    case EMAIL_VERIFICATION_TYPE:
      handleEmailVerification(domain);
      break;
    case PASSWORD_VERIFICATION_TYPE:
      handlePasswordVerification(domain);
      break;
    case RESET_PASSWORD_TYPE:
      handleResetPassword(domain);
      break;
    default:
      break;
  }
}

function DOMContentLoaded() {
  const type = getUrlParameter("type");
  const env = getUrlParameter("env");

  const domain = getDomainByEnv(env);
  if (domain === null) {
    handleMissingParams();
  }

  if (navigator.userAgent.indexOf("Chrome") != -1) {
    $(".add-to-chrome").addClass("show");
    const id = getChromeStoreIdByEnv(env);

    $(".add-to-chrome button").on("click", function () {
      window.open(
        `https://chrome.google.com/webstore/detail/amifi-wallet-stage/${id}`,
        "_blank"
      );
    });
  }

  $(".try-again-button").on("click", function () {
    $("body").addClass("loading");
    processByType(type, domain);
  });

  processByType(type, domain);

  $(".dropdown-toggle").dropdown();
}

document.addEventListener("DOMContentLoaded", DOMContentLoaded);
